const columns = Object.freeze([
  {
    key: 'select',
    thClass: 'text-center',
  },
  {
    key: 'name',
    label: 'Город',
    sortable: true,
    thClass: 'w-100 text-center',
    tdClass: 'text-center',
  },
  {
    key: 'status',
    label: 'Статус',
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-center',
  },
  {
    key: 'actions',
    label: '',
    thClass: 'text-center',
    tdClass: 'text-center',
  },
])

const actions = Object.freeze([
  {
    variant: 'primary',
    icon: 'EditIcon',
    emit: 'edit',
  },
])

const modal = {
  create: {
    title: 'Добавить Город',
    visible: false,
    okTitle: 'Создать',
  },
  edit: {
    title: 'Изменить',
    visible: false,
    okTitle: 'Изменить',
  },
  delete: {
    title: 'Вы действительно хотите удалить?',
    content: 'Вы действительно хотите удалить',
    visible: false,
    okTitle: 'Удалить',
  },
  image: {
    title: 'Фото',
    visible: false,
    okTitle: 'Закрыть',
  },
  status: {
    title: 'Статус',
    visible: false,
    okTitle: 'Изменить',
  },
}

export { columns, actions, modal }
