<template>
  <section>
    <table-header
      v-bind="{
        dateable: false,
        filterable: false,
        createTitle: !hasPermission ? 'Добавить город': '',
        currentPage: page,
      }"
      @update:page-size="onPerPageChange"
      @update:page="onPageChange"
      @update="onUpdateFilter"
      @reset="onResetFilter"
      @create="onCreate"
    >
      <template #actions-left>
        <b-button
          class="mb-1 mr-0 mb-md-0 mr-md-1"
          v-bind="{
            disabled: !selected.length,
            variant: 'success'
          }"
          @click="setStatus(1)"
        >
          <span> Активировать </span>
        </b-button>
        <b-button
          v-bind="{
            disabled: !selected.length,
            variant: 'danger'
          }"
          @click="setStatus(0)"
        >
          <span> Деактивировать </span>
        </b-button>
      </template>
      <template #actions-right>
        <b-form-select
          v-model="status"
          @input="loadCollection"
          :clearable="false"
          :options="statusCollection"
          size="md"
        />
      </template>
    </table-header>
    <table-body
      v-bind="{
        currentPage: page,
        pageCount,
        itemCount,
        pageSize: page_size
      }"
      @update:page-size="onPerPageChange"
      @update:page="onPageChange"
    >
      <b-table
        class="position-relative"
        v-bind="{
          items: cities,
          fields,
          striped,
          hover,
          responsive,
          perPage: page_size,
          showEmpty,
          emptyText,
          busy: loading,
        }"
        @sort-changed="onSort"
      >
        <template #head(select)>
          <b-form-checkbox
            v-bind="{
              checked: cities && selected.length === cities.length
            }"
            @change="onSelectAll()"
          ></b-form-checkbox>
        </template>

        <template #cell(select)="{ item }">
          <b-form-checkbox
            v-bind="{
              checked: selected.includes(item.id)
            }"
            @change="onSelectRow({ ...item })"
          />
        </template>

        <template #cell(status)="{ item }">
          <b-button
            v-bind:style="{
              width: '175px'
            }"
            v-bind="{
              block: true,
              variant: item.status ? 'success' : 'danger'
            }"
          >{{ item.status ? 'Активен' : 'Не активен' }}</b-button>
        </template>

        <template #cell(actions)="{ item }">
          <table-buttons
            v-bind="{
              disabled: hasPermission
            }"
            @edit="onEdit({ ...item })"
            hideDelete
          ></table-buttons>
          <!-- <table-buttons
            v-bind="{
              disabled: hasPermission
            }"
            @edit="onEdit({ ...item })"
            @delete="onDelete({ ...item })"
          ></table-buttons> -->
        </template>
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1"></b-spinner>
            <span>Загрузка...</span>
          </div>
        </template>
      </b-table>
    </table-body>

    <validation-observer v-slot="{ invalid }">
      <common-dialog
        v-bind="{
          title: modalConfig.title,
          value: modalConfig.value,
          okTitle: modalConfig.okTitle,
          okDisabled: invalid
        }"
        @confirm="onConfirm"
      >
        <template v-if="modal.delete.visible">
          <p> {{ `${modal.delete.content} ${form.name}?`}} </p>
        </template>
        <template v-if="modal.edit.visible || modal.create.visible">
          <validation-provider v-bind="{
              rules: {
                required: true
              }
            }">
            <b-form-group
              slot-scope="{ valid, errors }"
              v-bind="{
                  label: 'Название города'
                }"
            >
              <b-input
                v-model.trim="form.name"
                v-bind="{
                  state: errors[0] ? false : (valid ? true : null)
                }"
              />
            </b-form-group>
          </validation-provider>

          <b-form-group v-bind="{
            label: 'Статус'
          }">
            <b-select
              v-model="form.status"
              v-bind="{
                options: statusCollection.slice(1)
              }"
            />
          </b-form-group>
        </template>
      </common-dialog>
    </validation-observer>
  </section>

</template>

<script>
import { AuthNameSpace, RoleCollection, AuthMutationTypes } from '@/store/modules/auth/types'
import { mapState, mapActions } from 'vuex'
import { CityNameSpace, CityActionTypes } from '@/store/modules/city/types'
import { throttle, sortBy } from 'lodash'
import { duration } from '@/config'
import { BTable } from 'bootstrap-vue'

import TableHeader from '@/components/common/table/TableHeader'
import TableBody from '@/components/common/table/TableBody'
import TableButtons from '@/components/common/buttons/TableButtons'

import { columns, modal } from '@/config/cities'
import { statusCollection } from '@/config/shared'

export default {
  name: 'Clients',
  components: {
    BTable,
    TableHeader,
    TableBody,
    TableButtons,
    CommonDialog: () => import('@/components/common/common-dialog.vue'),
  },
  data() {
    return {
      status: '',
      page_size: 10,
      page: 1,
      modal,
      selected: [],
      search: '',
      form: {
        name: '',
        status: 1,
      },
      sort_by: {
        sortBy: 'name',
        sortDesc: 'asc',
      },
      loading: false,
    }
  },
  props: {
    striped: {
      type: Boolean,
      default() {
        return true
      },
    },
    hover: {
      type: Boolean,
      default() {
        return true
      },
    },
    responsive: {
      type: Boolean,
      default() {
        return true
      },
    },
    emptyText: {
      type: String,
      default() {
        return 'Нет данных'
      },
    },
    showEmpty: {
      type: Boolean,
      default() {
        return true
      },
    },
  },
  computed: {
    ...mapState(CityNameSpace, {
      cities: 'cities',
      city: 'city',
      pageCount: 'pageCount',
      itemCount: 'itemCount',
    }),
    ...mapState(AuthNameSpace, {
      userData: 'userData',
    }),
    fields() {
      return columns
    },
    statusCollection() {
      return statusCollection
    },
    modalConfig() {
      return {
        title: modal.create.visible ? modal.create.title : modal.delete.visible ? modal.delete.title : modal.edit.visible ? modal.edit.title : modal.image.visible ? modal.image.title : modal.status.title,
        value: modal.create.visible || modal.edit.visible || modal.delete.visible || modal.image.visible || modal.image.status,
        okTitle: modal.create.visible ? modal.create.okTitle : modal.delete.visible ? modal.delete.okTitle : modal.edit.visible ? modal.edit.okTitle : modal.image.visible ? modal.image.okTitle : modal.status.okTitle,
      }
    },
    hasPermission() {
      return this.userData.role === RoleCollection.florist || this.userData.role === RoleCollection.courier
    },
  },

  async beforeMount() {
    await this.loadCollection()
  },

  methods: {
    ...mapActions(CityNameSpace, {
      [CityActionTypes.LoadCityCollection]: CityActionTypes.LoadCityCollection,
      [CityActionTypes.CreateCity]: CityActionTypes.CreateCity,
      [CityActionTypes.UpdateCity]: CityActionTypes.UpdateCity,
      [CityActionTypes.DeleteCity]: CityActionTypes.DeleteCity,
      [CityActionTypes.SetStatusToCity]: CityActionTypes.SetStatusToCity,
      [CityActionTypes.LoadCity]: CityActionTypes.LoadCity,
    }),

    loadCollection: throttle(async function() {
      try {
        this.loading = true
        await this[CityActionTypes.LoadCityCollection]({ ...this.$data })
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }
    }, duration),

    load: throttle(async function({ id }) {
      await this[CityActionTypes.LoadCity]({ id })
    }, duration),

    onSelectRow({ id }) {
            this.selected.find(city => city === id) ? this.selected.splice(this.selected.indexOf(id), 1) : this.selected.push(id)
    },

    onSelectAll() {
            this.cities.length === this.selected.length ? (this.selected = []) : (this.selected = [...this.cities.map(({ id }) => id)])
    },

    async setStatus(status) {
      await this[CityActionTypes.SetStatusToCity]({ cities: this.selected.map(id => ({ id, status })) })
      await this.loadCollection()
      this.$store.commit(`${AuthNameSpace}/${AuthMutationTypes.SetCityCollection}`, {
        cities: sortBy(this.cities, 'name'),
      })

      this.selected = []
    },

    async onConfirm({ confirmed }) {
      if (confirmed) {
        try {
          if (this.modal.edit.visible) {
            await this[CityActionTypes.UpdateCity]({
              id: this.form.id,
              name: this.form.name,
              status: this.form.status,
              sort: this.form.sort,
            })
            await this.loadCollection()
          }

          if (this.modal.create.visible) {
            await this[CityActionTypes.CreateCity]({ ...this.form })
            await this.loadCollection()
          }

          if (this.modal.delete.visible) {
            await this[CityActionTypes.DeleteCity]({ ...this.form })

            if (this.cities.length === 1 && this.page !== 1) this.page -= 1

            await this.loadCollection()
          }
        } catch {
          for (const type in this.modal) {
            if (this.modal[type].visible) {
              this.modal[type].visible = false
            }
          }
        }
      }

      for (const type in this.modal) {
        if (this.modal[type].visible) {
          this.modal[type].visible = false
        }
      }
    },

    onCreate() {
      for (const key in this.form) {
        if (this.form[key]) {
                    key === 'status' ? (this.form[key] = 1) : (this.form[key] = '')
        }
      }

      this.modal.create.visible = true
    },

    async onEdit({ id }) {
      await this[CityActionTypes.LoadCity]({ id })

      this.form = { ...this.city }

      this.modal.edit.visible = true
    },

    onDelete({ id, name }) {
      this.form.id = id
      this.form.name = name

      this.modal.delete.visible = true
    },

    async onPerPageChange(count) {
      this.page = 1
      this.page_size = parseInt(count, 10)

      await this.loadCollection()
    },

    async onPageChange(count) {
      this.page = parseInt(count, 10)

      await this.loadCollection()
    },

    async onUpdateFilter(context) {
      Object.assign(this.$data, context)
      this.page = 1
      await this.loadCollection()
    },

    async onResetFilter() {
      Object.assign(this.$data, context)

      this.status = null

      await this.loadCollection()
    },

    async onSort({ sortBy, sortDesc }) {
      if (sortBy) {
        if (sortBy !== this.sort_by.sortBy) {
          this.sort_by.sortBy = sortBy
        }
        this.sort_by.sortDesc = sortDesc ? 'desc' : 'asc'
      }

      await this.loadCollection()
    },
  },
}
</script>

<style scoped>
</style>
